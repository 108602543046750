<template>
  <van-loading type="spinner" color="#1989fa" vertical>页面加载中...</van-loading>
</template>
<script>
import { api_template } from "@/api/api.js"
import { getQueryVariable, } from "@/util/utils.js"
export default {
  data () {
    return {
      supplierId: "",
      goodsId: "",
      skuId: "",
    }
  },
  created () {
    this.supplierId = getQueryVariable("supplierId")
    this.goodsId = getQueryVariable("id")
    this.skuId = getQueryVariable("skuId")
    const pageId = sessionStorage.getItem("pageId")
    if (pageId) {
      sessionStorage.removeItem('pageId')
      window.history.go(-1)
    } else {
      this.getTemplate()
    }
  },
  methods: {
    getTemplate () {
      const params = {
        supplierId: this.supplierId,
        showType: 4,
        goodsId: this.goodsId,
        skuId: this.skuId,
        host: window.location.href
      }
      api_template(params).then(res => {
        if (res.data.code == 0) {
          window.location.href = res.data.data
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.van-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
